<template>
  <v-container fluid>
    <v-row class="mx-auto">
      <v-col
        xs="12"
        sm="6"
        md="4"
        xl="3"
        v-for="(item, _key) in itens"
        :key="_key"
      >
        <v-hover v-slot:default="{ hover }">
          <v-card
            :id="_key"
            class="mx-auto my-auto px-2 d-flex"
            width="352px"
            height="100px"
            :color="hover ? 'grey lighten-2' : 'grey lighten-3'"
            elevation="1"
            :to="item.link"
            :disabled="item.disabled"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
            >
              <v-col cols="2">
                <v-icon
                  class="my-auto d-flex justify-center"
                  :size="item.icon_size || 40"
                  color="primary"
                >
                  {{ item.icon }}
                </v-icon>
              </v-col>
              <v-col cols="10">
                <v-card-text class="mx-auto my-auto">
                  <div
                    class="subtitle-2"
                    style="word-break: normal"
                  >
                    {{ item.name }}
                  </div>
                  <div class="caption text--secondary">
                    {{ item.description }}
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [profilePermissionsMixin],
  name: 'GeracaoBdgd',
  data: () => ({
    itens: []
  }),
  mounted() {
    this.setItens();
  },
  methods: {
    setItens() {
      if (this.accessReleased('EXTRAIR_BDGD_HISTORICO')) {
        this.itens.push({
          name: 'Extrair BDGD',
          link: '/geracao-bdgd/extrair-bdgd/historico',
          description:
            'Rode scripts para exportar dados do georreferenciado da distribuidora',
          icon: 'mdi-database-arrow-down-outline'
        });
      }

      if (this.accessReleased('IMPORTACOES_DE_BDGD')) {
        this.itens.push({
          name: 'Importar BDGD',
          link: '/geracao-bdgd/importacoes-bdgd/historico',
          description: 'Importe arquivos BDGD para o sistema',
          icon: 'mdi-database-marker-outline'
        });
      }

      if (this.accessReleased('VALIDACOES_DA_BDGD_HISTORICO')) {
        this.itens.push({
          name: 'Validar BDGD',
          link: '/geracao-bdgd/validacoes-bdgd/historico',
          description:
            'Execute os testes de validação da ANEEL - NVAL (16 etapas)',
          icon: 'mdi-database-check-outline'
        });
      }

      if (this.accessReleased('VALIDACOES_INDQUAL')) {
        this.itens.push({
          name: 'BDGD x INDQUAL',
          link: '/geracao-bdgd/validacoes-indqual',
          description:
            'Execute as metodologias ANEEL para validar a BDGD junto aos dados do INDQUAL',
          icon: 'mdi-file-compare'
        });
      }

      if (this.accessReleased('VALIDACOES_SAMP')) {
        this.itens.push({
          name: 'BDGD x SAMP',
          link: '/geracao-bdgd/validacoes-samp',
          description:
            'Compare os dados de energias das entidades da BDGD junto aos dados do SAMP',
          icon: 'mdi-file-table-box-multiple-outline'
        });
      }

      if (this.accessReleased('ATUALIZACOES_DE_BDGD')) {
        this.itens.push({
          name: 'Ajustar BDGD',
          link: '/geracao-bdgd/atualizacoes-bdgd/historico',
          description:
            'Atualize dados em tela, via Excel ou através de scripts modelos',
          icon: 'mdi-database-edit-outline'
        });
      }

      if (this.accessReleased('ATUALIZACOES_DDA')) {
        this.itens.push({
          name: 'Ajustar DDA',
          link: '/geracao-bdgd/atualizacoes-dda/historico',
          description:
            'Atualize dados de DDA em tela',
          icon: 'mdi-file-document-edit-outline'
        });
      }

      if (this.accessReleased('ATUALIZACOES_MCPSE')) {
        this.itens.push({
          name: 'Ajustar MCPSE',
          link: '/geracao-bdgd/atualizacoes-mcpse/historico',
          description:
            'Atualize dados de MCPSE em tela',
          icon: 'mdi-file-document-edit-outline'
        });
      }

      if (this.accessReleased('EXPORTAR_BDGD_HISTORICO')) {
        this.itens.push({
          name: 'Exportar BDGD',
          link: '/geracao-bdgd/exportar-bdgd/historico',
          description:
            'Selecione uma versão da BDGD e exporte-a para arquivos Shapefile',
          icon: 'mdi-file-marker-outline'
        });
      }
    }
  }
};
</script>
